<template>
  <Box>
    
    <Row>
      <Column>
        <Box>
          <Row>
        
            <Column :width="5">
              <LabelValue v-if="isAdmin">
                <template #label>Company</template>
                <template #value>{{ inspectionObj.company().find().name() }}</template>
              </LabelValue>
              
              <LabelValue>
                <template #label>Date</template>
                <template #value>{{ inspectionObj.createdDateDisplay() }}</template>
              </LabelValue>
              
              <LabelValue>
                <template #label>Inspection</template>
                <template #value>{{ inspectionObj.inspector().find().user().find().fullName() }}</template>
              </LabelValue>
              
              <LabelValue>
                <template #label>Driver</template>
                <template #value>{{ inspectionObj.driver().find().user().find().fullName() }}</template>
              </LabelValue>

              <LabelValue>
                <template #label>Trip Type</template>
                <template #value>{{ inspectionObj.isPreTrip() ? "Pre Trip" : "Post Trip" }}</template>
              </LabelValue>

              <LabelValue>
                <template #label>Overall</template>
                <template #value>
                  <Box>
                    <Row>
                      <div class="rc-box-col-5 rc-input-good rc-text-centre" v-if="inspectionObj.isDefectTypeGood()">
                        {{ inspectionObj.defectTypeDisplay() }}
                      </div>
                      <div class="rc-box-col-5 rc-input-minor rc-text-centre" v-else-if="inspectionObj.isDefectTypeMinor()">
                        {{ inspectionObj.defectTypeDisplay() }}
                      </div>
                      <div class="rc-box-col-5 rc-input-major rc-text-centre" v-else-if="inspectionObj.isDefectTypeMajor()">
                        {{ inspectionObj.defectTypeDisplay() }}
                      </div>
                      <div class="rc-box-col-10" />
                    </Row>
                  </Box>
                </template>
              </LabelValue>

              <LabelValue v-if="isAdmin">
                <template #label>Results</template>
                <template #value>All: {{ inspectionObj.results().size() }} Initial: {{ inspectionObj.results().find().initials().size() }} Enroute: {{ inspectionObj.results().find().enroutes().size() }}</template>
              </LabelValue>
              
              <LabelValue v-if="isAdmin">
                <template #label>Inspection Id</template>
                <template #value>{{ inspectionObj.id() }}</template>
              </LabelValue>
              
            </Column>
            
            <Column :width="5">
              <LabelValue>
                <template #label>Vehicle</template>
                <template #value>
                {{ displayVehicle(inspection['Vehicle']) }}
                </template>
              </LabelValue>

              <LabelValue>
                <template #label>Odometer</template>
                <template #value> {{ inspection.odometer }} </template>
              </LabelValue>
            
              <LabelValue>
                <template #label>Status</template>
                <template #value>
                  <Box>
                    <Row>
                      <Column />
                      <Column>
                          <div 
                            :class="toVehicleStatusColour(inspectionObj.vehicle())"
                            class="rc-text-centre">
                            {{ displayVehicleStatus(inspectionObj.vehicle()) }}
                          </div>
                      </Column>
                      <Column />
                    </Row>
                  </Box>
                </template>
              </LabelValue>
              
              <LabelValue>
                <template #label>Work Order</template>
                <template #value>
                {{ toWorkOrderNumber(inspection) }}
                </template>
              </LabelValue>
              
            </Column>
            
            <Column :width="5">
              <div v-for="(trailer, index) in trailers(inspection)" v-bind:key="index">
                <LabelValue>
                  <template #label>Trailer</template>
                  <template #value>
                    <Box>
                      <Row>
                        <Column :width="5">
                          <div class="clickable" v-on:click="filterByVehicle(trailer)">
                            {{ displayVehicle(trailer) }} 
                          </div>
                        </Column>
                        <Column :width="10"/>
                      </Row>
                    </Box>
                  </template>
                </LabelValue>
                
                <LabelValue>
                  <template #label>Status</template>
                  <template #value>
                    <Box>
                      <Row>
                        <Column :width="5">
                          <div :class="toVehicleStatusColour(trailer)" 
                            class="rc-text-centre">
                            {{ displayVehicleStatus(trailer) }}
                          </div>
                        </Column>
                        <Column  :width="10"/>
                      </Row>
                    </Box>
                  </template>
                </LabelValue>

              </div>
            </Column>
            
          </Row>
          
          <Row v-if="isAdmin" :showBorder="true">
            <div class="rc-box-col-2 rc-text-label">Company</div>
            <div class="rc-box-col-3 rc-text-value">
              <Value>{{ inspectionObj.company().find().name() }}</Value>
            </div>
            <div class="rc-box-col-1" v-if="!inspectionObj.isNew() && !inspectionObj.company().isNew()">
              <Button v-on:click="companyDetails(inspectionObj.company().id())">Details</Button>
            </div>
            <div class="rc-box-col-9" />
          </Row>
          
        </Box>
      </Column>
    </Row>
    
    <MenuRow :inspectionId="this.inspectionId" :isAdmin="isAdmin" @refresh="refresh"/>

    
  </Box>
</template>

<script>
import { mapGetters } from 'vuex';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import VehicleUtils from '@/views/portal/vehicles/Utils.js';

import Inspection from "@/domain/model/inspection/Inspection.js";
import Vehicle from "@/domain/model/vehicle/Vehicle.js";

import Box    from '@/portals/shared/library/box/Box.vue';
import Row    from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
import LabelValue from '@/portals/shared/library/labelvalue/LabelValue.vue';
import Value  from '@/portals/shared/library/value/Value.vue';
import MenuRow from '@/portals/customer/operator/views/inspection/MenuRow.vue';
import Button from "@/portals/shared/library/button/Button.vue";

export default {
  name: 'vehicle-inspection-summary',
  components: {
    MenuRow,
    Box,Row,Column, LabelValue, Value,
    Button,
  },
  props: {
    inspection: Object,
    displayVehicleName: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      images: {},
      isLoading: false,
    }
  },
  watch: {
    //watch
  },
  computed: {
    ...mapGetters([
      "auth_user",
      "auth_client",
      "repairResults_store",
      "auth_connected",
      "vehicles_found",
      "companys_found",
      "employees_found",
      "types_found",
      "makers_found",
      "users_store",
      "categorys_found",
      "results_found",
      "repairs_found",
      'domain',
    ]),
    inspectionObj: function() {
      if (this.domain) {
        return new Inspection(this.domain, this.inspection);
      }
      return new Inspection(this.domain);
    },
    inspectionId: function() {
      return this.inspectionObj.id();
    },
  },
  methods: {
    decode: function (value) {
      return ContentUtils.unescape(value)
    },
    toDateTime: function (dateValue) {
      return ContentUtils.decodeDateTime(dateValue)
    },
    toUser: function (ref) {
      if (!ref) {
        return ''
      }
      var employee = this.employees_found.map[ref[ConstUtils.FIELDS.ID]]
      if (!employee) {
        return ''
      }
      var userRef = employee.User[ConstUtils.FIELDS.ID]
      var user = this.users_store.map[userRef]
      return user.first + ' ' + user.last
    },
    toCompanyName: function (ref) {
      if (!ref) {
        return ''
      }
      var company = this.companys_found.map[ref[ConstUtils.FIELDS.ID]]
      if (!company) {
        return ''
      }
      return company.name;
    },
    toStatus: function (status) {
      return status
    },
    filterBy(employee) {
      if (employee) {
        var params = {
            id: employee[ConstUtils.FIELDS.ID],
          };
        this.$router.push({
          name: ConstUtils.ROUTES.EMPLOYEE_INSPECTIONS,
          params: params,
          });
      }
    },
    filterByVehicle(vehicle) {
      if (vehicle) {
        var params = {
            id: vehicle[ConstUtils.FIELDS.ID],
          }
        this.$router.push({
            name: ConstUtils.ROUTES.VEHICLE_DETAILS,
            params: params,
          })
      }
    },
    toResultColour: function (result, name) {
      if (name) {
        //
      }
      var colour = 'rc-input-good';
      if (result === 'Minor') {
        colour = 'rc-input-minor';
      }
      if (result === 'Major' || result == "major") {
        colour =  'rc-input-major';
      }
      return colour;
    },
    toResult: function (result) {
      var status = "Good"
      if (result == 'Minor') {
        status = 'Minor';
      }
      if (result == 'Major') {
        status = 'Major';
      }
      return status;
    },
    toWorkOrderNumber: function (inspection) {
      var results = inspection['VehicleInspectionRepairResultMap'];
      if (!results) {
        return "";
      }
      var repairResults = Object.keys(results).map((repair) => this.repairResults_store.map[repair]);
      return repairResults
        .sort(VehicleUtils.SortByModifiedDate)
        .reverse()
        .map((repair) => repair.workOrder)
        .join(', ')
    },
    displayVehicle: function (vehicleRef) {
      if (vehicleRef) {
        var vehicle = this.vehicles_found.map[vehicleRef[ConstUtils.FIELDS.ID]];
        if (vehicle) {
          return this.decode(vehicle.name);
        }
      }
      return "";
    },
    displayVehicleStatus: function (vehicleData) {
      var vehicle = new Vehicle(this.domain, vehicleData);
      var result = this.inspectionObj.results().find().forVehicle(vehicle);
      var defectType = result.mostSevereUnfixedDefectType();
      return this.toResult(defectType);
    },
    toVehicleStatusColour: function (vehicleData) {
      var vehicle = new Vehicle(this.domain, vehicleData);
      var name = vehicle.name();
      var result = this.inspectionObj.results().find().forVehicle(vehicle);
      var defectType = result.mostSevereUnfixedDefectType();
      return this.toResultColour(defectType, name);
    },
    trailers: function (inspection) {
      return inspection['trailerMap'];
    },
    hasUnfixedDefects: function() {
      var inspectionObj = new Inspection(this.domain, this.inspection);
      return inspectionObj.hasUnfixedDefect();
    },
    details: function () {
      var params = {
        id: this.inspection[ConstUtils.FIELDS.ID],
      }
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION_DETAILS,
        params: params,
      })
    },
    repairs: function() {
      var params = {
        id: this.inspection[ConstUtils.FIELDS.ID],
      };
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION_REPAIRS,
        params: params,
      });
    },
    rebuild: function() {
      this.isLoading = true;
      var event = this.domain
          .events()
          .inspections().rebuild(this.inspectionObj.id());
      event.send(this.listener);
    },
    emailInspection: function() {
      var params = {
          id: this.inspectionObj.id(),
        };
        
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION.EMAIL,
        params: params,
      });
    },
    refresh: function() {
      this.$emit("refresh");
    },
    
    companyDetails: function (companyId) {
      var params = {
          id: companyId, 
      };
      this.$router.push( {
            name: ConstUtils.ROUTES.COMPANY.ADMIN.DETAILS,
            params: params,
          });
    },
  },
}
</script>
