<template>
<Box>
  
  <ErrorRow :error="error" />
  
  <Row class="rc-text-label">
    
    <div class="rc-box-col-13 rc-background-dark-thin rc-text-medium">
    
      <Box>
        
        <Row>
          <div class="rc-box-col-14">
            {{ catelog.get(catelogKeys.FILTERS.TITLE) }}
          </div>
          <div class="rc-box-col-1">
            <Button 
              v-on:click="reset()" 
              :disabled="isLoading">
            Reset
            </Button>
          </div>
        </Row>

        <Row>
          <Column :width="10" :showBorder="true">
            <Box>
            <!-- Box -->
              <Row>
                <div class="rc-box-col-2">
                  {{ catelog.get(catelogKeys.FILTERS.STATUS) }}
                </div>
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setDefectFilterType('all')" 
                    :disabled="isDefectFilterType('all')">
                  All
                  </Button>
                </div>
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setDefectFilterType('good')" 
                    :disabled="isDefectFilterType('good')">
                  Good
                  </Button>
                </div>
                <div class="rc-box-col-2">
                  <Button
                    v-on:click="setDefectFilterType('minor')" 
                    :disabled="isDefectFilterType('minor')">
                  Minor
                  </Button>
                </div>
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setDefectFilterType('major')" 
                    :disabled="isDefectFilterType('major')">
                  Major
                  </Button>
                </div>
                <div class="rc-box-col-2">
                  <Button 
                      v-on:click="setDefectFilterType('any')" 
                      :disabled="isDefectFilterType('any')">
                    Any
                  </Button>
                </div>
                <div class="rc-box-col-3">
                  <Button 
                      v-on:click="setDefectFilterType('repaired')" 
                      :disabled="isDefectFilterType('repaired')">
                  Repaired
                  </Button>
                </div>
              </Row> <!-- row -->
              
              <Row>
                <div class="rc-box-col-2">
                  {{ catelog.get(catelogKeys.FILTERS.TIME) }}
                </div>
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setFilterDate('all')" 
                    :disabled="isDateFilter('all')">
                  All
                  </Button>
                </div>
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setFilterDate('today')" 
                    :disabled="isDateFilter('today')"> 
                  Today
                  </Button>
                </div>
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setFilterDate('yesterday')" 
                    :disabled="isDateFilter('yesterday')">
                  Yesterday
                  </Button>
                </div>
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setFilterDate('last2Weeks')" 
                    :disabled="isDateFilter('last2Weeks')">
                  Last 2 Weeks
                  </Button>
                </div>
      
                <div class="rc-box-col-2">
                  <Button 
                    v-on:click="setFilterDate('thisMonth')" 
                    :disabled="isDateFilter('thisMonth')" >
                  This Month
                  </Button>
                </div>
                <div class="rc-box-col-3">
                  <Button 
                    v-on:click="setFilterDate('last30Days')" 
                    :disabled="isDateFilter('last30Days')" >
                  Last 30 days
                  </Button>
                </div>
      
              </Row> <!-- row -->
            <!-- Box -->
            </Box>
          </Column>
          <Column :width="5" :showBorder="true">
            <Box>
              <KeyDate 
                title="jumpToDate" 
                placeholder="Show Inspections On or Before" 
                v-on:value="jumpToDate"
                hint="" 
                /> 
            </Box>
          </Column>
        </Row>
        
      </Box> <!-- container -->
      
    </div> <!-- col-11 -->
    
    <div class="rc-box-col-2 rc-background-dark-thin rc-font-medium">

      <Box>
        <Row>
          <div class="rc-box-col-15">Actions</div>
        </Row>
        <Row>
          <div class="rc-box-col-15">
            <Button v-on:click="refresh()" :disabled="isLoading">
             Refresh
            </Button>
          </div>
        </Row>
      </Box>
        
    </div>
    
  </Row>
  
  <TitleRow class="rc-text-small">
    <Box>
      <Row>
      <Column >Inspection</Column>
      <Column >Vehicle</Column>
      <Column >Trailers</Column>
      </Row>
    </Box>
  </TitleRow>
  
  <Row :showBorder="true">
    <Column>
      <Box>
      
        <Row v-for="(inspection, index) in inspections" :key="inspection['@rid']" :class="isEven(index) ? 'rowEven' : 'rowOdd'">
          <Column>
            <InspectionSummary :isAdmin="isAdmin" :inspection="inspection" @refresh="refresh"/>
          </Column>
        </Row>
          
      </Box>
    </Column>
  </Row>
          
  <Row :showBorder="true" v-if="inspections.length == 0 && !isLoading">
    <Column :width="5"> </Column>
    <Column :width="5">
        <Spacer />
        <Box :showBorder="true">
          <Title :subtitle="true"> No Results </Title>
          <Value align="left">
            There are no results for your current filters. Try using a filter 
            that can show data from a larger time window like:<br>
            <Box>
              <Row>
                <Column :width="10">
                  Show Inspections for this month
                </Column>
                <Column :width="5">
                  <Button 
                    v-on:click="setFilterDate('thisMonth')" 
                    :disabled="isDateFilter('thisMonth')" >
                    This Month
                  </Button>
                </Column>
              </Row>
              <Row>
                <Column :width="10">
                  Show Inspections for the last 2 years
                </Column>
                <Column :width="5">
                  <Button 
                    v-on:click="setFilterDate('last2Years')" 
                    :disabled="isDateFilter('last2Years')" >
                    Last 2 Years
                  </Button>
                </Column>
              </Row>
            </Box>
          </Value>
        </Box>
        <Spacer />
    </Column>
    <Column :width="5"> </Column>
  </Row>

  <LoadingRow :showBorder="true" v-if="isLoading"> {{ loadingMessage }} </LoadingRow>

  <Row v-if="!isLoading && inspections.length > 0" :showBorder="true">
    <div class="rc-box-col-4" />
    <div class="rc-box-col-1">
      <Button v-if="canPreviousPage()" v-on:click="previousPage"> &lt; </Button>
    </div>
    
    <div class="rc-box-col-5 rc-text-centre rc-text-label">
      Showing {{ min }} to {{ max }}  of {{ total }}
    </div>
    
    <div class="rc-box-col-1">
      <Button v-if="canNextPage()" v-on:click="nextPage"> &gt; </Button>
    </div>
    <div class="rc-box-col-4" />
  </Row>

</Box> <!-- container -->
</template>

<script>
import { mapGetters } from 'vuex'

import StringUtils  from '@/utils/StringUtils.js'
import Utils        from '@/views/portal/inspections/Utils.js'
//import ConstUtils  from '@/utils/ConstUtils.js'

import InspectionMap from '@/domain/model/inspection/InspectionMap.js';

import ErrorRow from '@/components/row/ErrorRow.vue'
import MC from "@/domain/session/MC.js";
import InspectionSummary  from '@/views/portal/inspections/list/InspectionSummary.vue'

import KeyDate from '@/components/input/KeyDate.vue';

import Catelog              from "@/domain/session/CanadianEnglish.js";
import InspectionListFilter from "@/domain/model/inspection/InspectionListFilter.js";

import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

import Value from '@/portals/shared/library/value/ValueRow.vue';
import Title from '@/portals/shared/library/title/TitleRow.vue';
import Spacer from '@/portals/shared/library/spacer/Spacer.vue';

import Button   from '@/portals/shared/library/button/Button.vue';
import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

export default {
  name: 'portal-inspection-list',
  components: {
    ErrorRow,
    Button,
    TitleRow, KeyDate,
    Box, Row, Column,
    Value, Title, Spacer, LoadingRow, 
    InspectionSummary,
  },
  props: {
    employee: { type: Object, default: null },
    vehicle: { type: Object, default: null },
    query: { type: Object, default: null },
    start: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      catelogKeys: Catelog.KEYS,
      catelog: new Catelog(),
      inspections: [],
      isLoading: false,
      loadingMessage: null,
      MC: new MC(),
      defectTypeFilter: 'all',
      currentDateFilter: 'last30Days',
      total: 0,
      pageSize: 20,
      pageNumber: 0,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'inspections_found',
                   'domain',
                   ]),
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.total < t ? this.total : t;
    },
    min: function() {
      if (this.total == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
  },
  watch: {
    start() {
      if (this.start) {
        if (this.$route.query.status) {
          this.defectTypeFilter = this.$route.query.status;
        }
        if (this.$route.query.time) {
          this.currentDateFilter = this.$route.query.time;
        }
        this.sendInitialRequest();
      }
    }
  },
  mounted: function () {
    if (this.start) {
      if (this.$route.query.status) {
        this.defectTypeFilter = this.$route.query.status;
      }
      if (this.$route.query.time) {
        this.currentDateFilter = this.$route.query.time;
      }
      this.sendInitialRequest();
    }
  },
  methods: {
    sendInitialRequest: function() {
      this.loadInspectionsByFilter();
    },
    
    loadInspectionsByFilter: function () {
      this.isLoading = true;
      this.loadingMessage = this.MC.Inspection.Status.Loading.value();
      this.inspections = [];
      if (!this.domain) {
        return;
      }
      var filter = new InspectionListFilter();
      filter
        .withDateRange(this.currentDateFilter)
        .withDefectType(this.defectTypeFilter)
        .withPagination(this.pageSize, this.pageNumber)
        .withEmployee(this.employee)
        .withVehicle(this.vehicle)
        .done();
      
      filter
        .withCompany(this.domain.session().company().id())
        .done();
      
      var event = this.domain
          .events()
          .inspections().list(filter);
      event.with("populate", true);  
      event.send(this.listener);
    },
    
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        this.total = payload.count();
        var map = payload.valuesFor(InspectionMap.MODEL_NAME);
        if (map) {
          var list = Object.values(map.data);
          this.inspections = list.sort(Utils.SortByCreatedDate);
        }
      }
      this.isLoading = false;
      this.loadingMessage = null;
    },
    
    isDefectFilterType: function(key) {
      if (this.defectTypeFilter === key) {
        return true;
      }
      return false;
    },
    
    isDateFilter: function (key) {
      if (this.currentDateFilter === key) {
        return true;
      }
      return false;
    },
    setFilterDate: function (key) {
      this.pageNumber = 0;
      this.currentDateFilter = key
      this.loadInspectionsByFilter();
    },
    
    setDefectFilterType: function(type) {
      if (type) {
        this.defectTypeFilter = type;
        this.pageNumber = 0;
      }
      this.loadInspectionsByFilter();
    },
    
    reset: function () {
      this.setFilterDate('today');
      this.setFilterType('all');
      this.pageNumber = 0;
      this.loadInspectionsByFilter();
    },
    
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    
    refresh: function () {
      this.loadInspectionsByFilter();
    },
    
    previousPage: function() {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.loadInspectionsByFilter();
      }
    },
    canPreviousPage: function() {
      return this.pageNumber > 0;
    },
    canNextPage: function() {
      return this.total > (this.pageSize * this.pageNumber) + this.pageSize
    },
    nextPage: function() {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.loadInspectionsByFilter();
      }
    },
    jumpToDate: function(kvp) {
      if (!kvp || !kvp.value) {
        this.setFilterDate('last30Days');
        return;
      }
      this.currentDateFilter = "(" + kvp.value + ")";
      this.loadInspectionsByFilter();
    },
  },
}
</script>